window.addEventListener("load", function () {
  //** Форма с загрузкой файла, главная */
  let formFwf = document.getElementById("form-with-files")
  function submitFwf(){
    if (formFwf) {
      const validate = new window.JustValidate(formFwf, {
        errorsContainer: document.querySelector(".fwf-errors-container")
      })
  
      validate
        .addField("#fwf-name", [
          {
            rule: "required",
            errorMessage: "Введите имя"
          },
          {
            rule: "minLength",
            value: 3,
            errorMessage: "Имя - Минимум три символа"
          },
          {
            rule: "maxLength",
            value: 30,
            errorMessage: "Имя - Максимум 30 символов"
          }
        ])
        .addField("#fwf-phone", [
          {
            rule: "minLength",
            value: 3,
            errorMessage: "Почта - Минимум три символа"
          },
          {
            rule: "required",
            errorMessage: "Почта или телефон обязательны"
          }
        ])
        .addField("#file-upload", [
          // {
          //   rule: "minFilesCount",
          //   value: 1,
          //   errorMessage: "Прикрепите файл"
          // },
          {
            errorMessage: 'Недопустимый формат файла',
            validator: function (value) {
              if (value[0]?.name.length > 0) {
                var fileExtension = value[0].name.split('.').pop().toLowerCase();
                console.log(fileExtension)
                if (['js', 'html', 'php', 'exe', 'py', 'pyc', 'sh'].includes(fileExtension)) {
                  return false
                } else return true
              } else return true
            } 
          }
        ])
        .onSuccess((event) => {
          event.preventDefault()
          fwfSubmit(event)
        })
    }
  }
 

  //** Форма обратного звонка + товары */
  let callbackForm = document.getElementById("callback-form")
  if (callbackForm) {
    const validateCallback = new window.JustValidate(callbackForm, {
      errorsContainer: document.querySelector(".cbf-errors-container")
    })
    validateCallback
      .addField("#cbf-name", [
        {
          rule: "required",
          errorMessage: "Введите имя"
        },
        {
          rule: "minLength",
          value: 3,
          errorMessage: "Имя - Минимум три символа"
        },
        {
          rule: "maxLength",
          value: 30,
          errorMessage: "Имя - Максимум 30 символов"
        }
      ])
      .addField("#cbf-phone", [
        {
          rule: "minLength",
          value: 3,
          errorMessage: "Почта - Минимум три символа"
        },
        {
          rule: "required",
          errorMessage: "Почта или телефон обязательны"
        }
      ])
      .onSuccess((event) => {
        event.preventDefault()
        cbfSubmit(event)
        validateCallback.refresh()
      
      })
  }
  //** Отправка заявки на лазерную резку */
  function fwfSubmit(event) {
    console.log("form clcik");
    var errorBlock = document.querySelector(".fwf-errors-container-onsend");
    var formData = new FormData(event.target);
    console.log("form send");
    fetch("/callbacks/ajaxRecive", {
      method: "POST",
      body: formData
    }).then(function (response) {
      console.log(response);
      if (response.ok) {
        return response.json(); // Вернуть обещание для разбора JSON
      } else {
        throw new Error("Произошла ошибка при отправке формы.");
      }
    })
    .then(function (parsedValue) {
      console.log(parsedValue);
      if (parsedValue.status === "ok") {
        console.log("Форма успешно отправлена.");
        errorBlock.style.display = "block";
        errorBlock.innerHTML = "Заявка успешно отправлена, с Вами свяжутся в ближайшее время.";
        // Дополнительные действия после успешной отправки формы
      } else {
        console.error("Произошла ошибка при отправке формы");
        errorBlock.style.display = "block";
        errorBlock.innerHTML = "Произошла ошибка при отправке формы";
      }

    })
    .catch(function (error) {
      // Обработка ошибок сети или других ошибок
      console.error("Произошла ошибка при отправке формы:", error);
      errorBlock.style.display = "block";
      errorBlock.innerHTML = "Произошла ошибка при отправке формы";
    });
  }
  //** отправка модала узнать цену, обратный звонок */
  function cbfSubmit(event) {
    grecaptcha.execute('6LeyJkEoAAAAAB3EhAncoJ8Ts3P9KLlfj7OjyyZZ', { action: 'callback' }).then(function (token) {
      var recaptchaResponse = document.getElementById('recaptchaСallback');
      recaptchaResponse.value = token;
      console.log("form clcik")
    let errorBlock = document.querySelector(".cbf-errors-container-submit")
    const formData = new FormData(event.target)

    //Определяем тип формы по заполненности поля с именем продукта
    let product = formData.get("tovarname")
    product == "" ? formData.append('callback_type', 'callback') : formData.append('callback_type', 'getprice')
        
    console.log("form send")
    // for (const pair of formData.entries()) {
    //     console.log(`${pair[0]}, ${pair[1]}`);
    // }
    fetch("/callbacks/ajaxRecive", {
      method: "POST",
      body: formData
    })
      .then((response) => {
          if (response.ok) {
            return response.json(); // Вернуть обещание для разбора JSON
          } else {
            throw new Error("Произошла ошибка при отправке формы.");
          }
        })
      .then(function (parsedValue) {
        if (parsedValue.status === "ok") {
          // Обработка успешного ответа
          console.log("Форма успешно отправлена.")
          errorBlock.style.display = "block"
          errorBlock.innerHTML = "Заявка успешно отправлена, с Вами свяжутся в ближайшее время!"
          callbackForm.style.display = "none"
          // Дополнительные действия после успешной отправки формы
        } else {
          console.error("Произошла ошибка при отправке формы.")
          callbackForm.style.display = "none"
          errorBlock.style.display = "block"
          errorBlock.innerHTML = "Произошла ошибка при отправке формы"
        }
      })
      .catch((error) => {
        // Обработка ошибок сети или других ошибок
        console.error("Произошла ошибка при отправке формы:", error)
        callbackForm.style.display = "none"
        errorBlock.style.display = "block"
        errorBlock.innerHTML = "Произошла ошибка при отправке формы"
      })
   });
  }

/** Google Капча */

grecaptcha.ready(function () {
  grecaptcha.execute('6LeyJkEoAAAAAB3EhAncoJ8Ts3P9KLlfj7OjyyZZ', { action: 'homemain' }).then(function (token) {
     var recaptchaResponse = document.getElementById('recaptchaResponse');
     recaptchaResponse.value = token;
     submitFwf();
  });
});
})
